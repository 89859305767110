<template>
  <div class="absolute inset-0 flex h-screen w-screen bg-white z-30">
    <div class="flex flex-col m-auto">
      <span class="m-auto"></span>
      <h2 class="m-auto text-2xl text-green-1001 font-bold">
        Enviando E-mail...
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderEmail'
};
</script>

<style scoped>
span {
  display: inline-block;
  width: 80px;
  height: 80px;
}
span:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #456a52;
  border-color: #456a52 transparent #456a52 transparent;
  animation: loading 1.2s linear infinite;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
