<template>
  <modal
    name="modal"
    :classes="
      padding
        ? ['main-vue-modal', 'rounded-3xl']
        : ['no-padding', 'rounded-3xl']
    "
    :width="width"
    :height="'auto'"
    :adaptive="true"
  >
    <header
      class="text-green-1002"
      :class="margin ? 'vue-modal-header' : 'no-margin'"
    >
      <slot name="header"></slot>
    </header>
    <section
      class="text-green-1002"
      :class="margin ? 'vue-modal-body' : 'no-margin'"
    >
      <div slot="top-right" style="position: absolute; top: 10px; right: 10px;">
        <button @click="$modal.hide('modal')">
          <i class="ri-close-fill text-black text-2xl"></i>
        </button>
      </div>
      <slot name="body"></slot>
    </section>
    <div class="flex justify-center">
      <slot name="footer"></slot>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'ModalLayout',

  data() {
    return {
      padding: true,
      margin: true,
      width: 600
    };
  },

  methods: {
    show() {
      this.$modal.show('modal');
    },
    hide() {
      this.$modal.hide('modal');
    },

    setModal() {
      if (this.$attrs.width) this.width = this.$attrs.width;

      this.padding = false;
      this.margin = false;
    }
  },

  mount() {
    this.show();
  },
  created() {
    this.setModal();
  }
};
</script>
<style>
.main-vue-modal {
  padding: 32px 16px;
}
.no-padding {
  padding: 0;
}
.rounded-3xl {
  border-radius: 1.5rem !important;
}
.vue-modal-header {
  font-size: 32px;
  text-align: center;
  margin-bottom: 16px;
}
.vue-modal-body {
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}
.no-margin {
  margin: 0;
}
.close {
  position: absolute;
  display: block;
  right: 0;
  top: -10px;
}
</style>
