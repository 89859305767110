<template>
  <dashboard-layout>
    <loader-email v-if="isLoading" />
    <div
      v-if="
        user.role.name === 'participant' ||
          user.role.name === 'associate' ||
          user.role.name === 'Instructor'
      "
      class="flex-col w-11/12 mx-auto mt-4"
    >
      <div class="divcarousel mb-4" v-if="loadbanner">
        <ssr-carousel
          class="carousel"
          :slides-per-page="1"
          show-dots
          show-arrows
          :autoplay-delay="5"
          loop
        >
          <div v-for="banner in banners" :key="banner.id">
            <a class="imagebanner" :href="banner.url" target="_blank"
              ><img :src="banner.image"
            /></a>
          </div>
        </ssr-carousel>
      </div>
      <h1 class="text-green-400 font-bold text-4xl w-full text-center mb-4">
        Próximos <span class="text-green-500">Cursos</span>
      </h1>
      <form @submit.prevent="submit" class="w-11/12 lg:w-9/12 mx-auto">
        <div class="flex flex-col">
          <div
            v-if="showAdvancedSearch"
            class="w-full bg-white rounded-lg py-5 px-28 mx-auto mt-3 "
          >
            <div class="flex flex-col w-full mb-5">
              <label class="flex items-center mb-2" for="course">
                Curso
                <field-tooltip
                  text="Selecione ou digite o nome do curso."
                ></field-tooltip>
              </label>
              <multiselect
                v-model="advancedSearch.courses.selected"
                id="course"
                label="name"
                track-by="name"
                placeholder="Pesquise o curso aqui..."
                open-direction="bottom"
                :options="advancedSearch.courses.options"
                :searchable="true"
                selected-label="Selecionado"
                deselect-label="Remover"
                select-label="Selecionar"
                :loading="advancedSearch.courses.isLoading"
                :internal-search="false"
                :options-limit="100"
                :max-height="600"
                :show-no-results="false"
                @search-change="fetchCourses"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>
                <span slot="noResult">Oops! Nenhum curso encontrado.</span>
                <span slot="noOptions"
                  >Comece a pesquisar para que os cursos apareçam aqui.</span
                >
              </multiselect>
            </div>
            <div class="flex flex-col w-full mb-5">
              <label class="flex items-center mb-2" for="cities">
                Municípios
                <field-tooltip
                  text="Informe o nome dos municípios dos quais deseja buscar talentos."
                ></field-tooltip>
              </label>
              <multiselect
                id="cities"
                v-model="advancedSearch.cities.selected"
                tag-placeholder="Adicionar cidade"
                placeholder="Procure ou adicione uma cidade"
                label="name"
                track-by="name"
                :options="advancedSearch.cities.filteredOptions"
                :multiple="true"
                :taggable="true"
                select-label="Selecionar"
                deselect-label="Remover"
                selected-label="Selecionado"
                @tag="addCity"
                :internal-search="false"
                @search-change="citiesSearchChange"
              >
                <span slot="noOptions"
                  >Comece a pesquisar para que as cidades apareçam aqui.</span
                >
              </multiselect>
            </div>
            <div class="flex flex-col w-full mb-5">
              <label class="flex items-center mb-2" for="cities">
                Marca de máquinas
                <field-tooltip
                  text="Procure cursos de capacitações em marcas de maquinas especificas."
                ></field-tooltip>
              </label>
              <multiselect
                v-model="advancedSearch.brand.selected"
                id="brand"
                label="brand"
                placeholder="Procure por máquinas especificas."
                open-direction="bottom"
                :options="advancedSearch.brand.options"
                :searchable="true"
                selected-label="Selecionado"
                deselect-label="Remover"
                select-label="Selecionar"
                :internal-search="false"
                :show-no-results="false"
              >
                <span slot="noResult"
                  >Oops! Nenhuma sugestão de experiência profissional
                  encontrado.</span
                >
                <span slot="noOptions"
                  >Comece a pesquisar para que as experiência profissionais
                  apareçam aqui.</span
                >
              </multiselect>
            </div>

            <button
              type="submit"
              class="focus:outline-none rounded-md py-2 bg-green-600 text-white text-center w-full text-xl cursor-pointer"
            >
              Aplicar filtros
            </button>
          </div>
        </div>
      </form>
      <div class="w-full lg:w-9/12 mx-auto">
        <div class="flex flex-col">
          <div
            v-if="true"
            class="w-full bg-white rounded-lg py-3 px-5 mx-auto mt-3"
          >
            <div class="flex flex-col w-full mb-5">
              <h1 class="text-2xl font-bold mb-5 text-center">
                Cronograma de Cursos
              </h1>
            </div>
            <loader-animation v-if="isLoadinglogo" />
            <div class="flex flex-col w-full mb-5" v-if="!isLoadinglogo">
              <div
                v-for="courseSchedule in courseSchedules.data"
                :key="courseSchedule.id"
              >
                <div
                  class="border-2 rounded-lg border-green-1002 -p-1 mb-8 flex"
                >
                  <div class="hidden md:block md:w-5/12">
                    <img
                      :src="
                        courseSchedule.image != 'null'
                          ? courseSchedule.image
                          : `https://via.placeholder.com/1080x768?text=${courseSchedule.course}`
                      "
                      alt="Imagem do Curso"
                      class="w-full h-full"
                    />
                  </div>
                  <div class="h-full flex items-start ml-3 w-full">
                    <div class="w-full ">
                      <div class="md:flex-grow">
                        <h2
                          class="text-xl font-medium text-gray-900 title-font py-4 text-center px-5"
                        >
                          {{ courseSchedule.course.toUpperCase() }}
                        </h2>
                      </div>

                      <div class="flex-grow">
                        <div class="flex">
                          <div class="flex items-center mx-1 w-2/5">
                            <div class="mx-4">
                              <i class="ri-map-pin-line"></i>
                            </div>
                            <div>
                              <span class="text-sm font-bold">Local</span>
                              <p class="text-black">
                                {{ courseSchedule.city }}
                              </p>
                            </div>
                          </div>

                          <div class="flex items-center mx-1">
                            <div class="mx-4">
                              <i class="ri-calendar-todo-fill"></i>
                            </div>
                            <div>
                              <span class="text-sm font-bold"
                                >Periodo do Curso</span
                              >
                              <p class="text-black">
                                {{ courseSchedule.dateEvent }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="flex mt-4">
                          <div
                            class="flex items-center mx-1 w-2/5"
                            v-if="courseSchedule.brand"
                          >
                            <div class="mx-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="1rem"
                              >
                                <path
                                  d="M11.8611 2.39057C12.8495 1.73163 14.1336 1.71797 15.1358 2.35573L19.291 4.99994H20.9998C21.5521 4.99994 21.9998 5.44766 21.9998 5.99994V14.9999C21.9998 15.5522 21.5521 15.9999 20.9998 15.9999H19.4801C19.5396 16.9472 19.0933 17.9102 18.1955 18.4489L13.1021 21.505C12.4591 21.8907 11.6609 21.8817 11.0314 21.4974C10.3311 22.1167 9.2531 22.1849 8.47104 21.5704L3.33028 17.5312C2.56387 16.9291 2.37006 15.9003 2.76579 15.0847C2.28248 14.7057 2 14.1254 2 13.5109V6C2 5.44772 2.44772 5 3 5H7.94693L11.8611 2.39057ZM4.17264 13.6452L4.86467 13.0397C6.09488 11.9632 7.96042 12.0698 9.06001 13.2794L11.7622 16.2518C12.6317 17.2083 12.7903 18.6135 12.1579 19.739L17.1665 16.7339C17.4479 16.5651 17.5497 16.2276 17.4448 15.9433L13.0177 9.74551C12.769 9.39736 12.3264 9.24598 11.9166 9.36892L9.43135 10.1145C8.37425 10.4316 7.22838 10.1427 6.44799 9.36235L6.15522 9.06958C5.58721 8.50157 5.44032 7.69318 5.67935 7H4V13.5109L4.17264 13.6452ZM14.0621 4.04306C13.728 3.83047 13.3 3.83502 12.9705 4.05467L7.56943 7.65537L7.8622 7.94814C8.12233 8.20827 8.50429 8.30456 8.85666 8.19885L11.3419 7.45327C12.5713 7.08445 13.8992 7.53859 14.6452 8.58303L18.5144 13.9999H19.9998V6.99994H19.291C18.9106 6.99994 18.5381 6.89148 18.2172 6.68727L14.0621 4.04306ZM6.18168 14.5448L4.56593 15.9586L9.70669 19.9978L10.4106 18.7659C10.6256 18.3897 10.5738 17.9178 10.2823 17.5971L7.58013 14.6247C7.2136 14.2215 6.59175 14.186 6.18168 14.5448Z"
                                ></path>
                              </svg>
                            </div>
                            <div>
                              <span class="text-sm font-bold"
                                >Marca da Máquina</span
                              >
                              <p class="text-black">
                                {{ courseSchedule.brand }}
                              </p>
                            </div>
                          </div>

                          <div class="flex items-center mx-1 w-2/5">
                            <div class="mx-4">
                              <i class="ri-information-line"></i>
                            </div>
                            <div>
                              <span class="text-sm font-bold">Informações</span>
                              <p class="text-black">
                                {{ courseSchedule.telephone }}
                              </p>
                              <p class="text-black">
                                {{ courseSchedule.email }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="flex my-5 mr-5 justify-end">
                          <button
                            v-if="
                              courseSchedule.participant_id == 0 &&
                                user.role.name !== 'associate'
                            "
                            type="button"
                            class="relative inline-block flex-shrink-0 h-10 w-60 border-2 rounded-full border-green-1002 cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
                            @click="newInterest(courseSchedule)"
                          >
                            Tenho Interesse
                            <i
                              class="ri-arrow-right-line text-green-500 ml-2"
                            ></i>
                          </button>
                          <button
                            v-if="
                              user.role.name === 'associate' &&
                                courseSchedule.isInterest === null
                            "
                            type="button"
                            @click="showModal(courseSchedule)"
                            class="relative flex justify-center	items-center flex-shrink-0 h-10 w-40 border-2 rounded-full border-green-1002 cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
                          >
                            Saiba mais...
                            <i
                              class="ri-arrow-right-line text-green-500 ml-2"
                            ></i>
                          </button>
                          <button
                            v-if="
                              user.role.name === 'associate' &&
                                courseSchedule.isInterest !== null
                            "
                            type="button"
                            @click="showModal(courseSchedule)"
                            class="relative inline-block flex-shrink-0 h-10 w-60 border-2 text-white rounded-full border-green-1002 bg-green-1002 cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
                          >
                            Acompanhar Interesse
                            <i class="ri-arrow-right-line ml-2"></i>
                          </button>
                          <span
                            class="title-font font-bold text-gray-900"
                            v-if="courseSchedule.participant_id != 0"
                          >
                            Solicitação enviada
                            <i class="ri-mail-send-line ml-2 pt-1"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <modal-layout :width="'90%'">
      <template v-slot:body>
        <div class="container flex">
          <div class="hidden md:block md:w-5/12">
            <img
              :src="
                infoModal.image != 'null'
                  ? infoModal.image
                  : `https://via.placeholder.com/1080x768?text=${infoModal.course}`
              "
              alt="Imagem do Curso"
              class="w-full h-full"
            />
          </div>
          <div
            class="w-full md:w-7/12 flex flex-col items-center justify-center"
          >
            <div>
              <h1 class="text-2xl text-green-900 font-bold mb-1">
                {{ infoModal.course }}
              </h1>
              <div
                class="flex justify-center text-base text-green-900 font-bold"
              >
                <span>{{ infoModal.dateEvent }}</span>
              </div>
            </div>
            <div class="flex justify-evenly items-baseline flex-wrap p-2">
              <div class="flex items-center flex-wrap">
                <i class="ri-time-line text-green-600 text-2xl"></i>
                <strong class="mx-1">Carga Horaria</strong>
                <span class="text-sm text-black flex-basis-0 ml-6">{{
                  infoModal.workload
                }}</span>
              </div>
              <div class="flex items-center flex-wrap minimium_age">
                <i class="ri-user-line text-green-600 text-2xl"></i>
                <strong class="mx-1">Idade Mínima:</strong>
                <span class="text-sm text-black basis-full flex-basis-0 ml-6">{{
                  infoModal.age_prerequisite
                }}</span>
              </div>
              <div class="flex items-center flex-wrap">
                <i class="ri-checkbox-circle-line text-green-600 text-2xl"></i>
                <strong class="mx-1">Vagas</strong>
                <span class="text-sm text-black basis-full flex-basis-0 ml-6">{{
                  infoModal.maximum_vacancies
                }}</span>
              </div>
              <div class="flex items-center flex-wrap">
                <i class="ri-map-pin-line text-green-600 text-2xl"></i>
                <strong class="mx-1">Município</strong>
                <span class="text-sm text-black basis-full flex-basis-0 ml-6">{{
                  infoModal.city
                }}</span>
              </div>

              <div
                class="w-11/12 mt-1.5 border-b border-gray-200 dark:border-gray-700"
              >
                <ul
                  class="flex flex-wrap -mb-px text-sm font-bold text-center"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li class="mr-2" role="presentation">
                    <button
                      :class="tabActive == 0 ? 'bg-gray-200 font-bold' : ''"
                      class="inline-block p-1.5 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300"
                      id="apresentation-tab"
                      data-tabs-target="#apresentation"
                      type="button"
                      role="tab"
                      aria-controls="apresentation"
                      aria-selected="false"
                      @click="tabIndex(0)"
                    >
                      Aprensentação
                    </button>
                  </li>
                  <li class="mr-2" role="presentation">
                    <button
                      :class="tabActive == 1 ? 'bg-gray-200 font-bold' : ''"
                      class="inline-block p-1.5 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300"
                      id="objective-tab"
                      data-tabs-target="#objective"
                      type="button"
                      role="tab"
                      aria-controls="objective"
                      aria-selected="false"
                      @click="tabIndex(1)"
                    >
                      Objetivo
                    </button>
                  </li>
                  <li class="mr-2" role="presentation">
                    <button
                      :class="tabActive == 2 ? 'bg-gray-200 font-bold' : ''"
                      class="inline-block p-1.5 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300"
                      id="learn-tab"
                      data-tabs-target="#learn"
                      type="button"
                      role="tab"
                      aria-controls="learn"
                      aria-selected="false"
                      @click="tabIndex(2)"
                    >
                      O que vou aprender
                    </button>
                  </li>
                </ul>
              </div>
              <div id="myTabContent" class="w-11/12">
                <div
                  :class="tabActive == 0 ? '' : 'hidden'"
                  class="p-4 bg-gray-200 h-28 overflow-auto"
                  id="apresentation"
                  role="tabpanel"
                  aria-labelledby="apresentation-tab"
                >
                  <p class="text-sm text-gray-500">
                    {{ infoModal.presentation }}
                  </p>
                </div>
                <div
                  :class="tabActive == 1 ? '' : 'hidden'"
                  class="p-4 bg-gray-200 h-28 overflow-auto"
                  id="objective"
                  role="tabpanel"
                  aria-labelledby="objective-tab"
                >
                  <p class="text-sm text-gray-500">
                    {{ infoModal.objective }}
                  </p>
                </div>
                <div
                  :class="tabActive == 2 ? '' : 'hidden'"
                  class="p-4 bg-gray-200 h-28 overflow-auto"
                  id="learn"
                  role="tabpanel"
                  aria-labelledby="learn-tab"
                >
                  <p class="text-sm text-gray-500">
                    {{ infoModal.programmatic_content }}
                  </p>
                </div>
              </div>

              <fieldset class="border border-green-1002 rounded-lg pt-3 px-5">
                <legend class="text-black text-xl">
                  Tenho interesse
                </legend>

                <div class="flex flex-row justify-start items-center mb-5">
                  <div class="flex w-6/12 flex-col mr-5">
                    <label for="" class="text-black text-base"
                      >Quantidade de colaboradores a ser capacitado</label
                    >
                    <input
                      v-model="interestNumberParticipants"
                      @change="
                        infoModal.isInterest !== null
                          ? changeNumberParticipant()
                          : ''
                      "
                      type="number"
                      min="1"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 leading-tight focus:outline-none focus:bg-white"
                    />
                  </div>
                  <div class="flex w-6/12 flex-col mr-5">
                    <div class="flex items-center flex-wrap">
                      <i class="ri-building-line text-green-600 text-2xl"></i>
                      <strong class="mx-1">Contato do Sindicato Rural</strong>
                      <span
                        class="text-sm text-black basis-full flex-basis-0 ml-6"
                        >{{ infoModal.telephone }}</span
                      >
                      <span
                        class="text-sm text-black basis-full flex-basis-0 ml-6"
                        >{{ infoModal.email }}</span
                      >
                    </div>
                  </div>
                </div>
                <hr class="col" />

                <div
                  class="flex w-full flex-col mr-5"
                  v-if="infoModal.isInterest === null"
                >
                  <div class="flex items-center flex-wrap">
                    <label for="" class="text-black text-base"
                      >Observações</label
                    >
                    <textarea
                      v-model="interestMessage"
                      rows="5"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Sugestão de local de realização. Disponibilidade de parceria com recursos para o treinamento."
                    ></textarea>
                  </div>
                </div>

                <div
                  class="flex w-full flex-col mr-5"
                  v-if="infoModal.isInterest === null"
                >
                  <div class="flex items-center justify-end flex-wrap">
                    <button
                      @click="sendInterest"
                      class="bg-green-1000 justify-end text-white px-6 py-3 rounded-md focus:outline-none"
                    >
                      Enviar interesse
                    </button>
                  </div>
                </div>

                <div
                  class="h-60 overflow-auto w-full text-black inline-block"
                  v-if="infoModal.isInterest !== null"
                >
                  <loader-animation
                    v-if="loadmessage"
                    style="margin-top:4rem;"
                    :height="'h-75'"
                  />
                  <div
                    class="border border-green-600 p-1.5 mt-3 w-3/5 rounded-md"
                    :class="value.associate_name ? 'float-right' : 'float-left'"
                    v-for="(value, key) in modalMessage.message"
                    :key="key"
                  >
                    <span class="text-xs"
                      >{{ value.user_name }} – {{ value.created_at }}</span
                    >
                    <div>
                      <p>
                        {{ value.message }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="flex justify-between items-baseline"
                  v-if="infoModal.isInterest !== null"
                >
                  <input
                    type="text"
                    id="message"
                    placeholder="Envie sua mensagem"
                    autocomplete="off"
                    class="appearance-none block w-11/12 bg-white text-gray-700 border border-gray-600 rounded-full py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    v-model="message"
                  />
                  <button
                    class="bg-green-1000 text-white py-2 px-3 rounded-full border border-gray-400 focus:outline-none"
                    @click="sendMessage"
                  >
                    <i class="ri-send-plane-fill"></i
                    ><span class="">Enviar</span>
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </template>
    </modal-layout>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../layouts/DashboardLayout';
import FieldTooltip from '../../components/FieldTooltip';
import store from '@/store';
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';
import LoaderEmail from '../../components/LoaderEmail.vue';
import LoaderAnimation from '../../components/LoaderAnimation.vue';
import ModalLayout from '../../components/Modal';

export default {
  name: 'Index',
  components: {
    DashboardLayout,
    LoaderEmail,
    LoaderAnimation,
    ModalLayout,
    FieldTooltip
  },

  data() {
    return {
      tabActive: 0,
      banners: [],
      infoModal: [],
      interestNumberParticipants: '',
      interestMessage: '',
      message: '',
      loadmessage: true,
      loadbanner: false,
      modalMessage: {
        message: []
      },

      isLoadinglogo: false,
      courseSchedules: [],
      pagination1: {},
      cityIdUser: '',
      cityNameUser: '',
      telephoneParticipant: '',
      search: '',
      isLoading: false,
      showAdvancedSearch: true,
      searchCourses: false,

      advancedSearch: {
        courses: {
          isLoading: false,
          options: [],
          draw: 0,
          selected: null
        },
        brand: {
          options: [],
          selected: null
        },
        cities: {
          options: [],
          filteredOptions: [],
          selected: []
        }
      }
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      associate: 'associate',
      isAvailable: 'isAvailable'
    })
  },

  props: {
    participant: {
      required: true,
      type: Number
    }
  },

  methods: {
    async fetchbanners() {
      await axios.get('api/banners/viewbanner/slider').then(response => {
        response.data.data.forEach(ban => {
          if (ban.image) {
            this.banners.push({
              image: ban.image,
              id: ban.id,
              url: ban.url_link
            });
          }
        });
      });
      this.loadbanner = true;
    },

    async fetchParticipant() {
      const participant = await axios.get('/api/perfil');
      if (participant.data.data) {
        this.telephoneParticipant =
          participant.data.data.cellphone == ''
            ? participant.data.data.telephone
            : participant.data.data.cellphone;
      }
    },
    async fetchAddress() {
      if (this.user.role.name === 'Instructor') {
        this.advancedSearch.cities.selected.push({
          name: 'Cuiabá',
          id: '5220'
        });
      }

      const response = await axios.get(`/api/perfil/endereco`);
      this.cityIdUser = response.data.data.city.id;
      this.cityNameUser = response.data.data.city.name;
      this.advancedSearch.cities.selected.push({
        name: response.data.data.city.name,
        id: response.data.data.city.id
      });
      store.commit('setCities', this.advancedSearch.cities.selected);
      this.fetchCourseSchedules();
    },

    async fetchCourseSchedules() {
      this.isLoadinglogo = true;
      axios
        .post('/api/cronogramacursos', {
          cities: store.state.cities.map(city => city.id) ?? '',
          course: store.state.course ?? '',
          brand: store.state.brand,
          participant: this.user.participant_id ?? '',
          associate: this.associate.id ?? ''
        })
        .then(({ data }) => {
          this.courseSchedules = data;
          this.pagination1 = data;
          this.isLoadinglogo = false;
        });

      this.courseSchedules.data.find((element, index) => {
        if (element.isInterest !== this.infoModal.isInterest)
          this.courseSchedules.data.splice(--index, 1);
      });
    },

    searchBrand() {
      axios.get('api/palavras-chave/course-brand').then(({ data }) => {
        console.log(data.data);
        this.advancedSearch.brand.options = data.data;
      });
    },

    tabIndex(index) {
      this.tabActive = index;
    },

    showModal(courseSchedule) {
      this.loadmessage = true;
      this.modalMessage.message = [];
      this.infoModal = courseSchedule;

      this.interestNumberParticipants = '';

      axios
        .get(
          `/api/areas-de-interesse/mensagens/${courseSchedule.course_schedules_id}/${this.infoModal.isInterest}`
        )
        .then(({ data }) => {
          this.interestNumberParticipants =
            data.data[0].number_participants ?? '';

          data.data.forEach(message => {
            this.modalMessage.message.push(message);
          });
        })
        .finally(() => {
          this.loadmessage = false;
        });

      this.$modal.show('modal');
    },

    sendMessage() {
      axios
        .post(`/api/areas-de-interesse/enviar-mensagem/${this.user.id}`, {
          message: this.message,
          associate_course_interest_id: this.modalMessage.message.at(0)
            .associate_course_interest_id
        })
        .then(({ data }) => {
          this.$toast.success(data.data.message);
          this.$modal.hide('modal');
          this.message = '';
        });
    },

    changeNumberParticipant() {
      axios
        .put(
          `/api/cronogramacursos/atualizar-participantes/${this.infoModal.isInterest}`,
          {
            number_participants: this.interestNumberParticipants
          }
        )
        .then(({ data }) => {
          this.$toast.success(data.data.message);
        });
    },

    sendInterest() {
      axios
        .post(
          `/api/cronogramacursos/associados-interessados/${this.infoModal.course_schedules_id}`,
          {
            user: this.user.id,
            associate_id: this.associate.id,
            number_participants: this.interestNumberParticipants,
            message: this.interestMessage,
            status: 1
          }
        )
        .then(({ data }) => {
          this.$toast.success(data.data.message);

          this.interestMessage = '';
          this.interestNumberParticipants = '';

          this.$modal.hide('modal');
        })
        .catch(({ response }) => {
          Object.values(response.data.errors).forEach(error => {
            this.$toast.error(...error);
          });
        });
    },

    newInterest(information) {
      this.$confirm({
        message:
          'Será enviado um email para o sindicado com seu interesse de participar deste curso. Confirma o interesse?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            if (this.user.role.name !== 'Instructor') {
              this.isLoading = true;
              axios
                .post(`/api/participar-evento/${this.user.participant_id}`, {
                  participant_id: this.user.participant_id,
                  course_schedule_id: information.course_schedules_id,
                  name: this.user.name,
                  telephone_participant: this.telephoneParticipant,
                  email_participant: this.user.email,
                  course: information.course,
                  date: information.dateEvent,
                  city: information.city,
                  telephone_syndicate: information.telephone,
                  email_syndicate: information.email
                })
                .then(({ data }) => {
                  this.isLoading = false;
                  this.emailSucess(data.message);
                })
                .catch(({ response }) => {
                  Object.values(response.data.errors).forEach(error => {
                    this.$toast.error(...error);
                  });
                });
            } else {
              this.emailSucess('E-mail enviado ao sindicato rural com sucesso');
            }
          }
        }
      });
    },

    emailSucess(mensagem) {
      this.$confirm({
        title: 'Informação',
        message: mensagem,
        button: {
          yes: 'OK'
        },
        callback: confirm => {
          if (confirm) {
            if (this.user.role.name !== 'Instructor') {
              this.$router.go();
            }
          }
        }
      });
    },

    participantEvent(courseID, emailSyndicate) {
      axios
        .post(`/api/participar-evento/${this.user.participant_id}`, {
          participant_id: this.user.participant_id,
          course_schedule_id: courseID,
          email: emailSyndicate
        })
        .then(({ data }) => {
          alert(data.message);
        })
        .catch(({ response }) => {
          Object.values(response.data.errors).forEach(error => {
            this.$toast.error(...error);
          });
        });
    },

    fetchCourses(search) {
      this.advancedSearch.courses.isLoading = true;
      this.advancedSearch.courses.draw++;

      axios
        .post('api/cursos/buscar', {
          name: search,
          draw: this.advancedSearch.courses.draw
        })
        .then(({ data }) => {
          if (this.advancedSearch.courses.draw !== data.draw) return;

          this.advancedSearch.courses.options = [];

          data.data.forEach(course => {
            this.advancedSearch.courses.options.push({
              name: course.name,
              id: course.id
            });
          });

          this.advancedSearch.courses.draw = 0;
        })
        .finally(() => {
          this.advancedSearch.courses.isLoading = false;
        });
    },

    addCity(city) {
      const tag = {
        name: city,
        id: city
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    async fetchCities() {
      await axios.get('api/estados/11/cidades').then(response => {
        response.data.data.forEach(city => {
          this.advancedSearch.cities.options.push({
            name: city.name,
            id: city.id
          });
        });
      });
    },

    submit: function() {
      store.commit('setSearch', this.search);

      if (this.advancedSearch.courses.selected != null) {
        store.commit('setCourse', this.advancedSearch.courses.selected.id);
      } else {
        store.commit('setCourse', null);
      }

      let cities = this.advancedSearch.cities.selected;

      store.commit('setCities', cities);

      store.commit(
        'setBrand',
        this.advancedSearch.brand.selected
          ? this.advancedSearch.brand.selected['brand']
          : null
      );

      localStorage.setItem(
        'searchParams',
        JSON.stringify({
          'Palavra Buscada': this.search,
          Curso: this.advancedSearch.courses.selected
            ? this.advancedSearch.courses.selected.name
            : null,
          'Cidades Selecionadas': cities ?? null
        })
      );
      this.searchCourses = true;
      this.fetchCourseSchedules();
    },

    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },
    citiesSearchChange(search) {
      this.advancedSearch.cities.filteredOptions = search
        ? this.advancedSearch.cities.options.filter(option =>
            this.normalizedContains(search, option.name)
          )
        : this.advancedSearch.cities.options;
    },

    verifyCurriculum() {
      if (localStorage.getItem('ComplementaryCurriculum') != 'true') {
        axios
          .get(`/api/participantes/${this.user.participant_id}`)
          .then(response => {
            if (
              response.data.data.academicBackgrounds.length == 0 &&
              response.data.data.experiences.length == 0 &&
              response.data.data.complementaryCourses.length == 0 &&
              response.data.data.interestAreas.length == 0
            ) {
              localStorage.setItem('ComplementaryCurriculum', false);

              if (localStorage.getItem('ComplementaryCurriculum') == 'false')
                this.$router.push({ name: 'my-complete-curriculum' });
            }
          });
      }
    },

    setCityAssociate() {
      if (this.user.role.name === 'associate') {
        axios
          .get(`/api/cronogramacursos/cidade-associado/${this.user.id}`)
          .then(({ data }) => {
            this.advancedSearch.cities.selected.push({
              name: data.data.name,
              id: data.data.id
            });
            store.commit('setCities', this.advancedSearch.cities.selected);
            this.fetchCourseSchedules();
          });
      }
    }
  },

  async created() {
    await this.fetchbanners();
    this.verifyCurriculum();
    this.fetchParticipant();
    this.fetchAddress();
    this.fetchCities();
    this.setCityAssociate();
    this.searchBrand();
  }
};
</script>

<style scoped>
.flex-basis-0 {
  flex-basis: 100%;
}

.minimium_age {
  flex-basis: 185px;
}

.divcarousel {
  display: flex;
  justify-content: center;
}

.carousel {
  width: 70%;
}

a.imagebanner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
</style>
