var render = function render(){var _vm=this,_c=_vm._self._c;return _c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: this.text,
    placement: 'right',
    classes: [],
    targetClasses: ['it-has-a-tooltip'],
    delay: {
      show: 300,
      hide: 300
    }
  }),expression:"{\n    content: this.text,\n    placement: 'right',\n    classes: [],\n    targetClasses: ['it-has-a-tooltip'],\n    delay: {\n      show: 300,\n      hide: 300\n    }\n  }"}],staticClass:"ri-lightbulb-line ml-1"})
}
var staticRenderFns = []

export { render, staticRenderFns }