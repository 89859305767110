<template>
  <i
    class="ri-lightbulb-line ml-1"
    v-tooltip="{
      content: this.text,
      placement: 'right',
      classes: [],
      targetClasses: ['it-has-a-tooltip'],
      delay: {
        show: 300,
        hide: 300
      }
    }"
  ></i>
</template>

<script>
export default {
  name: 'FieldTooltip',
  props: ['text']
};
</script>

<style scoped></style>
